import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import Logo from "../pages/icons/merdeo-logo.png";
import Footer from "../components/footer";
import WaitList from "../components/wait-list"

const ComponentName = () => {
  const menu = ["Home", "Tech", "Company", "Support", "Join"];



  return (
    <>
      <div className="bg-gray-900">
        <div className="relative py-10 px-8 mx-auto max-w-7xl bg-gray-900">
          <nav className="flex justify-between items-center bg-gray-900">
            <Link to="/">
              <img className="h-8 md:h-11" alt="Logo" src={Logo} />
            </Link>
            <ul className="hidden md:items-center md:tracking-wider md:text-gray-300 md:gap-8 lg:gap-x-16 md:flex">
              {menu.map((item, index) => (
                <li key={index}>
                  <Link
                    to={`/${item.toLowerCase()}`}
                    className="transition hover:text-white"
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
            {/* <button
                                className="hidden py-3 px-6 font-medium tracking-wider leading-5 text-white rounded-md border-2 border-gray-800 transition md:inline-block hover:bg-teal-300 hover:text-gray-900 hover:shadow-lg"
                            >
                                Login
                            </button>
                            */}
          </nav>
        </div>
        <section className="relative pt-12 overflow-hidden bg-gray-900 sm:pt-16">
          <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="max-w-4xl mx-auto text-center">
              <p className="text-sm font-normal tracking-widest uppercase">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-yellow-500">
                  {" "}
                  Connecting Urban Vendors with Local Farmers{" "}
                </span>
              </p>
              <h1 className="mt-8 text-2xl font-semibold text-white sm:text-5xl lg:text-3xl xl:text-3xl">
              Experience the convenience of a Platform that connects you directly to local farmers. Try Merdeo now and enjoy affordable farm fresh produce at your doorsteps!!
              </h1>
            </div>

            <div className="relative mt-12 -mb-4 sm:-mb-10 lg:-mb-12 sm:mt-16 lg:mt-24">
              <div className="absolute top-0 transform -translate-x-1/2 left-1/2">
                <svg>
                  <linearGradient
                    id="d"
                    x1="665.741"
                    y1="178.506"
                    x2="296.286"
                    y2="474.62"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0%" className="text-cyan-500" />
                    <stop offset="100%" className="text-purple-500" />
                  </linearGradient>
                </svg>
              </div>

              <div className="absolute inset-0"></div>
            </div>
          </div>
        </section>
      </div>

      {/*
  Heads up! 👋

  Plugins:
    - @tailwindcss/forms
*/}

     <WaitList />
      {/*

*/}
      <Footer />
    </>
  );
};
export default ComponentName;
