import React from 'react'
import Logo from '../pages/icons/merdeo-logo.png'
import { useNavigate } from "react-router-dom"


function Footer (){

  let navigate = useNavigate();

const Support  = () => {
    navigate("/support")
}

const Tech  = () => {
  navigate("/tech")
}

const Company  = () => {
  navigate("/company")
}

const Join  = () => {
  navigate("/join")
}

    return(
        <>
      <section class="bg-white py-28 text-dark">
        <div class="mx-auto px-6 md:max-w-2xl lg:max-w-7xl">
          <div class="grid grid-cols-2 gap-x-6 gap-y-12 lg:grid-cols-6">
            <div class="col-span-2 max-w-[200px]">
            <img className="h-8 md:h-11" alt="Logo" src={Logo} />
            <ul class="flex flex-col gap-5 pt-6">
                <li class="text-dark">
                  <a href="#">Shalom Estates, Adenta </a>
                </li>
                <li class="text-dark">
                  <a href="#">+233 (0) 53 632 9815</a>
                </li>
                <li class="text-dark">
                  <a href="#">support@merdeofoods.com</a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-xl font-semibold tracking-tight text-gray-500">
                Merdeo Foods
              </h3>
              <ul class="flex flex-col gap-5 pt-6">
              <li class="text-dark" onClick={Tech}>
                  <a href="#">Tech</a>
                </li>
                <li class="text-dark">
                  <a href="#" onClick={Company}>Company</a>
                </li>
                <li class="text-dark" onClick={Support}>
                  <a href="#">Support</a>
                </li>
                <li class="text-dark" onClick={Join}>
                  <a href="#">Join Us</a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-xl font-semibold tracking-tight text-gray-500" onClick={Company}>
                Company
              </h3>
              <ul class="flex flex-col gap-5 pt-6">
                <li class="text-dark">
                  <a href="#" onClick={Company}>About Us</a>
                </li>
                <li class="text-dark">
                  <a href="#" onClick={Support}>Help Center</a>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="text-xl font-semibold tracking-tight text-gray-500">
                Follow Us
              </h3>
              <ul class="flex flex-col gap-5 pt-6">
                <li class="text-dark">
                  <a href="https://gh.linkedin.com/company/merdeofoods">LinkedIn</a>
                </li>
                <li class="text-dark">
                  <a href="https://www.instagram.com/merdeofoods/">Instagram</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="mt-16 flex flex-col gap-12 border-t border-gray-200 pt-8 lg:flex-row lg:items-center lg:justify-between"
          >
            <div class="flex flex-col gap-8 lg:flex-row lg:items-center">
              <span class="font-semibold text-gray-500"></span>
              <span class="hidden text-gray-500 lg:inline"> </span>
              <span class="font-semibold text-gray-500"> </span>
              <span class="hidden text-gray-500 lg:inline"> </span>
              <span class="font-semibold text-gray-500">  </span>
            </div>
            <span class="text-dark">© Merdeo Foods 2024</span>
          </div>
        </div>
      </section>
        </>
    )
}

export default Footer