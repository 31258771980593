import React from "react";
import MerdeoPlatform from "../assets/merdeo-images/merdeo-platform.png"

const ComponentName = () => {
  return (
    <div className="">
      <section class="py-20 bg-gray-50 text-gray-900 border" id="waitlist">
        <div className="mx-auto px-6 md:max-w-2xl lg:max-w-7xl">
          <div className=" grid grid-cols-1 items-center md:pr-20 lg:grid-cols-3 lg:pr-0">
            <div>
              <span className="text-3xl font-semibold uppercase tracking-normal text-green-600 lg:text-3xl lg:leading-relaxed">
                Join The Waitlist!
              </span>
              <h2 class="pt-2 pb-5 text-2xl font-mons leading-normal tracking-normal lg:pt-0 lg:text-[25px] lg:leading-normal lg:tracking-tight">
                Join the 100+ restaurateurs that have already signed up.
              </h2>
              <form
                action="https://getform.io/f/panvnkza"
                method="POST"
                className="space-y-6"
              >
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label
                      className="sr-only rounded-lg border border-gray-200"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                      placeholder="Name"
                      type="text"
                      id="name"
                      name="name"
                    />
                  </div>

                  <div>
                    <label className="sr-only" htmlFor="phone">
                      Phone
                    </label>
                    <input
                      className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                      placeholder="Phone Number"
                      type="tel"
                      id="phone"
                      name="phone"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label className="sr-only" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                      placeholder="Email address"
                      type="email"
                      id="email"
                      name="email"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                  >
                    Join waitlist
                  </button>
                </div>
              </form>
            </div>
            <div class="col-span-2 p-7 bg-gray-50 mx-1 lg:mx-4 xl:mx-4 my-4">
              <img src={MerdeoPlatform} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ComponentName;
