import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import Logo from '../pages/icons/merdeo-logo.png';
import Footer from '../components/footer'
import Veggies from '../assets/veggies.jpg'
import Illustration from '../assets/illustration.png'
import Merdeo from '../assets/merdeo-images/merdeo-2.jpeg'
import MerdeoTwo from '../assets/merdeo-images/merdeo-3.jpeg'
import MerdeoThree from '../assets/merdeo-images/merdeo-4.jpeg'
import MerdeoFour from '../assets/merdeo-images/merdeo-5.jpeg'
import Hendrix from '../assets/hendrix.jpeg'
import Charles from '../assets/charles.jpg'

const ComponentName = () => {
    const menu = ['Home', 'Tech', 'Company', 'Support', 'Join'];


    return (
        <>
            <div className="bg-gray-900">
                <div className="relative py-10 px-8 mx-auto max-w-7xl bg-gray-900">
                    <nav className="flex justify-between items-center bg-gray-900">
                        <Link to="/">
                            <img className="h-8 md:h-11" alt="Logo" src={Logo} />
                        </Link>
                        <ul className="hidden md:items-center md:tracking-wider md:text-gray-300 md:gap-8 lg:gap-x-16 md:flex">
                            {menu.map((item, index) => (
                                <li key={index}>
                                    <Link to={`/${item.toLowerCase()}`} className="transition hover:text-white">
                                        {item}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        {/* <button
                                className="hidden py-3 px-6 font-medium tracking-wider leading-5 text-white rounded-md border-2 border-gray-800 transition md:inline-block hover:bg-teal-300 hover:text-gray-900 hover:shadow-lg"
                            >
                                Login
                            </button>
                            */}
                    </nav>
                </div>
                <section className="relative pt-12 overflow-hidden bg-gray-900 sm:pt-16">
                    <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                        <div className="max-w-4xl mx-auto text-center">
                            <p className="text-sm font-normal tracking-widest uppercase">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-yellow-500"> Connecting Urban Vendors with Local Farmers </span>
                            </p>
                            <h1 className="mt-8 text-4xl font-semibold text-white sm:text-5xl lg:text-6xl xl:text-7xl">We are reinventing Africa's food system- together</h1>
                        </div>

                        <div className="relative mt-12 -mb-4 sm:-mb-10 lg:-mb-12 sm:mt-16 lg:mt-24">
                            <div className="absolute top-0 transform -translate-x-1/2 left-1/2">
                                <svg>
                                    <linearGradient id="d" x1="665.741" y1="178.506" x2="296.286" y2="474.62" gradientUnits="userSpaceOnUse">
                                        <stop offset="0%" className="text-cyan-500" />
                                        <stop offset="100%" className="text-purple-500" />
                                    </linearGradient>
                                </svg>
                            </div>

                            <div className="absolute inset-0">
                                <img className="object-cover w-full h-full opacity-50" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/noise.png" alt="alt image" />
                            </div>

                            <img className="relative w-full max-w-5xl mx-auto" src={Veggies} alt="veggies image" />
                        </div>
                    </div>
                </section>
            </div>


            <div class="mx-auto px-6 pt-20 md:max-w-2xl lg:max-w-7xl">
                <div
                    class="grid grid-cols-1 items-center md:pr-20 lg:grid-cols-3 lg:pr-0"
                >
                    <div>
                        <span
                            class="font-semibold uppercase tracking-normal text-green-800 lg:text-lg lg:leading-relaxed"
                        >
                        </span>
                        <h2
                            class="pt-2 text-3xl font-bold leading-normal tracking-normal lg:pt-0 lg:text-[40px] lg:leading-normal lg:tracking-tight"
                        >
                           About Merdeo
                        </h2>
                    </div>
                    <div class="col-span-2">
                        <p
                            class="max-w-3xl pt-2 leading-loose text-gray-600 lg:pt-0 lg:text-xl lg:leading-relaxed"
                        >
Merdeo's solution is to connect small restaurants and food businesses directly to local farmers through our
 e-commerce platform and cold storage houses. Merdeo is committed to revolutionizing the way small and medium-sized restaurants procure ingredients. 
 Our mission is to empower food businesses with direct access to fresh farm produce while saving them time, 
 money, and resources.                        
 </p>
                    </div>
                </div>
            </div>



            <section className="relative py-12 sm:py-16 lg:pb-40">
                <div className="absolute bottom-0 right-0 overflow-hidden">
                    <img className="w-full h-auto origin-bottom-right transform scale-150 lg:w-auto lg:mx-auto lg:object-cover lg:scale-75" src="https://cdn.rareblocks.xyz/collection/clarity/images/hero/1/background-pattern.png" alt="alt image" />
                </div>

                <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-y-4 lg:items-center lg:grid-cols-2 xl:grid-cols-2">
                        <div className="text-center xl:col-span-1 lg:text-left md:px-10 lg:px-0 xl:pr-20">
                            <h1 className="text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight font-pj">Building a future-ready food ecosystem in Africa with technology</h1>
                            <div className="mt-8 sm:mt-16">
                                <div className="flex items-center justify-center mt-3 lg:justify-start">
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-1">
                            <img className="w-full mx-auto" src={Illustration} alt="illustration" />
                        </div>
                    </div>
                </div>
            </section>

            <div class="container mx-auto px-4 py-8">
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-20 gap-x-4">
    <div class="w-full h-64 bg-gray-300 rounded-md ">
    <img src={Merdeo} class='object-cover rounded-md'/>
    </div>
    <div class="w-full h-64 bg-gray-300 rounded-md">
    <img src={MerdeoTwo} class='object-cover rounded-md'/>
    </div>
    <div class="w-full h-64 bg-gray-300 rounded-md">
    <img src={MerdeoThree} class='object-cover rounded-md'/>
    </div>
    <div class="w-full h-64 bg-gray-300 rounded-md">
    <img src={MerdeoFour} class='object-cover rounded-md'/>
    </div>
    <div class="w-full h-64 bg-gray-300 rounded-md">        
    <img src={Merdeo} class='object-cover rounded-md'/>
    </div>
    <div class="w-full h-64 bg-gray-300 rounded-md">
    <img src={MerdeoTwo} class='object-cover rounded-md'/>
    </div>
  </div>
</div>


            <section className="py-6 bg-white sm:py-8 lg:py-10">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl py-6 sm:py-4 lg:py-5">The Merdeo Team</h2>
                    <div className="grid grid-cols-2 gap-6 mt-2 lg:mt-2 lg:gap-4 lg:grid-cols-4">
                        <div className="relative group">
                            <div className="overflow-hidden aspect-w-1 aspect-h-1">
                                <img className="object-cover w-full h-full transition-all duration-300 group-hover:scale-125" src={Hendrix} alt="CEo Hendrix" />
                            </div>
                            <div className="flex items-start justify-between mt-4 space-x-4">
                                <div>
                                    <h3 className="text-sm font-bold text-gray-900 sm:text-sm md:text-lg">
                                        <a href="#" title="">
                                           Nana Kofi Sarpong
                                            <span className="absolute inset-0" aria-hidden="true"></span>
                                        </a>
                                    </h3>
                                    <h3 className="text-sm text-gray-900 sm:text-sm md:text-sm">
                                        <a href="#" title="">
                                           Chief Executive Officer
                                            <span className="absolute inset-0" aria-hidden="true"></span>
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div className="relative group">
                            <div className="overflow-hidden aspect-w-1 aspect-h-1">
                                <img className="object-cover w-full h-full transition-all duration-300 group-hover:scale-125" src={Charles} alt="CTO Charles" />
                            </div>
                            <div className="flex items-start justify-between mt-4 space-x-4">
                                <div>
                                    <h3 className="text-xs font-bold text-gray-900 sm:text-sm md:text-lg">
                                        <a href="#" title="">
                                            Charles Amoah Ansong
                                            <span className="absolute inset-0" aria-hidden="true"></span>
                                        </a>
                                    </h3>
                                    <h3 className="text-sm  text-gray-900 sm:text-sm md:text-sm">
                                        <a href="#" title="">
                                           Chief Technology Officer
                                            <span className="absolute inset-0" aria-hidden="true"></span>
                                        </a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





<Footer/>



        </>
    )
}
export default ComponentName;
