import React from 'react' 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from './pages/homepage';
import Product from './pages/product'
import Company from './pages/company';
import Media from './pages/resources'
import Support from './pages/support'
import Join from "./pages/join"
import Tech from './pages/tech'

const App = () => {
  return (


      <div>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/home" element={<Homepage />} />  
                  <Route path="/product" element={<Product />} /> 
                  <Route path="/company" element={<Company />} />                                                                    
                  {/* <Route path="/media" element={<Media />} />                                                                     */}
                  <Route path="/support" element={<Support />} />  
                  <Route path="/join" element={<Join />} /> 
                  <Route path="/tech" element={<Tech />} />                                                                                                                 
              </Routes>
          </BrowserRouter>
          {
              
          }

          </div>
             )
}

export default App;
