import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../pages/icons/merdeo-logo.png";
import Header from "../assets/header.png";

function Navigation() {
  const menu = ["Home", "Tech", "Company", "Support", "Join"];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div className="overflow-hidden w-full min-h-screen font-sans bg-gray-900">
        <div className="relative">
          <div className="relative py-10 px-8 mx-auto max-w-7xl">
            <nav className="flex justify-between items-center">
              <Link to="/">
                <img className="h-8 md:h-11" alt="Logo" src={Logo} />
              </Link>
              <button
                className="md:hidden text-white focus:outline-none"
                onClick={toggleMenu}
              >
                {/* Hamburger icon (you can replace this with your preferred icon) */}
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                  />
                </svg>
              </button>
              <ul
                className={`md:flex text-white md:items-center md:tracking-wider md:gap-8 lg:gap-x-16 ${
                  isMenuOpen ? "flex flex-col mt-4 md:mt-0" : "hidden md:flex"
                }`}
              >
                {menu.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={`/${item.toLowerCase()}`}
                      className={`transition ${
                        isMenuOpen
                          ? "block mb-4 md:inline-block"
                          : "hover:text-white"
                      }`}
                      onClick={() => setIsMenuOpen(false)}
                      style={{ color: isMenuOpen ? "white" : "" }}
                    >
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
              {/* <button
                                className="hidden py-3 px-6 font-medium tracking-wider leading-5 text-white rounded-md border-2 border-gray-800 transition md:inline-block hover:bg-teal-300 hover:text-gray-900 hover:shadow-lg"
                            >
                                Login
                            </button>
                            */}
            </nav>
          </div>
          <div class="relative py-10 px-8 mx-auto max-w-7xl">
            <div class="flex flex-col gap-y-12 md:flex-row">
              <div class="md:w-1/2">
                <h1 class="relative z-10 pt-10 text-5xl font-bold leading-tight text-white md:text-6xl lg:text-7xl">
                  <span class="relative after:bottom-2 after:left-0 after:bg-gradient-to-l after:from-teal-300 after:absolute after:w-80 after:h-5 after:-z-10">
                    Connecting Food Businesses
                  </span>
                  &nbsp; to
                </h1>
                <h1 class="relative z-10  text-5xl font-bold leading-tight text-white md:text-6xl lg:text-7xl">
                  {" "}
                  Farm Fresh Foodstock
                </h1>
                <p class="pt-2 max-w-lg text-xl tracking-wide leading-relaxed text-gray-300">
                  We are empowering urban vendors and food businesses with
                  direct access to farm-fresh produce and cloud-based restaurant
                  services for financial growth
                </p>
                <div class="flex gap-x-6 items-center pt-12">
                  {/*<button
                                class="hidden py-3 px-6 font-medium tracking-wider leading-5 text-white rounded-md border-2 border-gray-800 transition md:inline-block hover:bg-teal-300 hover:text-gray-900 hover:shadow-lg"
                            >
                                Get Started
                            </button>*/}
                </div>
              </div>
              <div class="flex items-center md:justify-center md:pl-24 md:w-1/2 ">
                <img class="lg:max-w-xl drop-shadow-2xl" src={Header} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
