import React from 'react'
import { Link } from 'react-router-dom'; // Import Link from React Router
import Logo from '../pages/icons/merdeo-logo.png';
import Footer from '../components/footer'


function Product() {
    const menu = ['Home', 'Company', 'Product', 'Media', 'Support'];


    return (
        <>

            <div className="overflow-hidden w-full min-h-screen font-sans bg-gray-900">
                <div className="relative">
                    <div className="relative py-10 px-8 mx-auto max-w-7xl">
                        <nav className="flex justify-between items-center">
                            <Link to="/">
                                <img className="h-8 md:h-11" alt="Logo" src={Logo} />
                            </Link>
                            <ul className="hidden md:items-center md:tracking-wider md:text-gray-300 md:gap-8 lg:gap-x-16 md:flex">
                                {menu.map((item, index) => (
                                    <li key={index}>
                                        <Link to={`/${item.toLowerCase()}`} className="transition hover:text-white">
                                            {item}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                            <button
                                className="hidden py-3 px-6 font-medium tracking-wider leading-5 text-white rounded-md border-2 border-gray-800 transition md:inline-block hover:bg-teal-300 hover:text-gray-900 hover:shadow-lg"
                            >
                                Login
                            </button>
                        </nav>
                    </div>
                </div>
                <div class="relative min-h-screen w-full bg-gray-900 text-white">
                    <img
                        src="/img/circle.svg"
                        alt=""
                        class="absolute top-0 left-0 -translate-x-1/4 -translate-y-1/4 opacity-40"
                    />
                    <div class="absolute inset-y-0 right-0 w-2/5 bg-gray-900"></div>
                    <div class="relative mx-auto max-w-7xl px-4 pt-8">
                        <div class="grid grid-cols-2 pt-6">
                            <div class="max-w-xl pr-24">
                                <div class="pt-16">            <h1
                                    class="bg-gradient-to-br from-green-500 to-amber-400 bg-clip-text pt-6 text-6xl font-bold tracking-tight text-transparent"
                                >
                                    Join our waiting list
                                    &#8212; We'll send you a download link
                                </h1>
                                    <img src="/img/decoration.svg" alt="" class="-mt-2 w-96" />
                                    <div
                                        class="mt-10 flex items-center rounded-xl shadow-glow transition-shadow ease-out focus-within:shadow-teal-500/40"
                                    >
                                        <input
                                            type="text"
                                            class="flex-1 rounded-l-xl border-2 border-r-0 border-gray-700 bg-gray-900 px-8 py-4 leading-6 focus:outline-none"
                                            placeholder="Enter your email here"
                                        />
                                        <button
                                            class="rounded-r-xl border-2 border-l-0 border-gray-700 bg-teal-500 px-8 py-4 text-lg font-bold leading-6 text-gray-900"
                                        >
                                            Send
                                        </button>
                                    </div>

                                    <div class="flex items-center gap-4 pt-12">

                                    </div>
                                </div>
                            </div>
                            <div class="pt-10">
                                <div class="flex justify-center gap-8">
                                    <img
                                        src="/img/screen-1.png"
                                        alt=""
                                        class="h-full w-64 animate-hover"
                                    />
                                    <img
                                        src="/img/screen-2.png"
                                        alt=""
                                        class="h-full w-64 animate-hover pt-20"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )

}

export default Product;


