import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import Logo from "../pages/icons/merdeo-logo.png";
import Footer from "../components/footer";
import Supplier from "../assets/invent.jpg";
import BackImage from "../assets/restaurant-owner.jpg";
import Inventory from "../assets/payment.jpg";
import AutomatedOrdering from "../assets/new-automated.jpg";
import WaitList from "../components/wait-list"
const ComponentName = () => {
  const menu = ["Home", "Tech", "Company", "Support", "Join"];

  return (
    <>
      <div className="bg-gray-900">
        <div className="relative py-10 px-8 mx-auto max-w-7xl bg-gray-900">
          <nav className="flex justify-between items-center bg-gray-900">
            <Link to="/">
              <img className="h-8 md:h-11" alt="Logo" src={Logo} />
            </Link>
            <ul className="hidden md:items-center md:tracking-wider md:text-gray-300 md:gap-8 lg:gap-x-16 md:flex">
              {menu.map((item, index) => (
                <li key={index}>
                  <Link
                    to={`/${item.toLowerCase()}`}
                    className="transition hover:text-white"
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <section
          className="bg-cover bg-center h-screen"
          style={{ backgroundImage: `url(${BackImage})` }}
        >
          <div className="bg-black bg-opacity-50 h-full">
          <div class="relative py-10 px-8 mx-auto max-w-7xl">
            <div class="flex flex-col gap-y-12 md:flex-row">
              <div class="md:w-1/2">
                <h1 class="relative z-10 pt-10 text-5xl font-bold leading-tight text-white md:text-6xl lg:text-7xl">
                  <span class="relative after:bottom-2 after:left-0 after:bg-gradient-to-l after:from-teal-300 after:absolute after:w-80 after:h-5 after:-z-10">
                    The Procurement Platform
                  </span>
                  &nbsp; for
                </h1>
                <h1 class="relative z-10  text-5xl font-bold leading-tight text-white md:text-6xl lg:text-7xl">
                  {" "}
                  Food Businesses in Africa
                </h1>
              </div>
            </div>
          </div>
            <div className="flex items-end justify-start">
              <h1 className="text-white text-3xl font-bold m-10  lg:w-1/2 ">
                The platform helps you process orders, accept payment and
                connects you direcly to your suppliers. We give you everything
                you need to run your business.
              </h1>
            </div>
          </div>
        </section>

        <section class="bg-white py-28 text-gray-900">
          <div class="mx-auto px-6 md:max-w-2xl lg:max-w-7xl">
            <div class="grid grid-cols-1 items-center md:pr-20 lg:grid-cols-3 lg:pr-0">
              <div>
                <span class="font-bold uppercase tracking-normal text-green-800 lg:text-3xl lg:leading-relaxed">
                  The Merdeo Solution
                </span>
                <h2 class="pt-2 text-3xl font-bold leading-normal tracking-normal lg:pt-0 lg:text-[40px] lg:leading-normal lg:tracking-tight">
                  Easy, Simple, & Innovative
                </h2>
              </div>
              <div class="col-span-2">
                <p class="max-w-3xl pt-2 leading-loose text-gray-600 lg:pt-0 lg:text-xl lg:leading-relaxed">
                  This comprehensive platform integrates seamlessly with
                  existing restaurant management systems, offering a suite of
                  features to simplify and enhance procurement workflows. Key
                  benefits include:
                </p>
              </div>
            </div>
            <div class="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-6">
              <div class="flex flex-col gap-6 md:flex-row md:items-start lg:flex-col">
                <img
                  class="aspect-square w-full max-w-sm rounded-xl object-cover md:w-1/4 lg:w-full"
                  src={Inventory}
                  alt=""
                />
                <div>
                  <h3 class="text-xl font-semibold tracking-tight lg:text-2xl lg:leading-normal">
                    Inventory Tracking
                  </h3>
                  <p class="mt-2 leading-relaxed text-gray-600 md:pr-20 lg:mt-4 lg:pr-0">
                    Real-time inventory tracking ensures accurate stock levels,
                    minimizing waste and spoilage. The system also provides
                    alerts for perishable items nearing their expiration dates.
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-6 md:flex-row md:items-start lg:flex-col">
                <img
                  class="aspect-square w-full max-w-sm rounded-xl object-cover md:w-1/4 lg:w-full"
                  src={AutomatedOrdering}
                  alt=""
                />
                <div>
                  <h3 class="text-xl font-semibold tracking-tight lg:text-2xl lg:leading-normal">
                    Automated Ordering
                  </h3>
                  <p class="mt-2 leading-relaxed text-gray-600 md:pr-20 lg:mt-4 lg:pr-0">
                    The platform automates the ordering process by tracking
                    inventory levels in real-time and placing orders with
                    suppliers automatically when stock is low, reducing the risk
                    of overstocking or shortages.
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-6 md:flex-row md:items-start lg:flex-col">
                <img
                  class="aspect-square w-full max-w-sm rounded-xl object-cover md:w-1/4 lg:w-full"
                  src={Supplier}
                  alt=""
                />
                <div>
                  <h3 class="text-xl font-semibold tracking-tight lg:text-2xl lg:leading-normal">
                    Supplier Management
                  </h3>
                  <p class="mt-2 leading-relaxed text-gray-600 md:pr-20 lg:mt-4 lg:pr-0">
                    The platform maintains a centralized database of suppliers,
                    enabling easy comparison of prices and terms. It also
                    facilitates direct communication and negotiation with
                    vendors, ensuring the best deals and prompt deliveries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <WaitList />
      </div>

      <Footer />
    </>
  );
};
export default ComponentName;
