import React from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as CheckCircleIcon } from "./icons/check-circle.svg";
import { Link } from "react-router-dom";
import Navigation from "../components/navigation";
import WaitList from "../components/wait-list"
import Footer from "../components/footer";
import Veggies from "../assets/veggies.jpeg";
import Payment from "../assets/payment.jpeg";
import Delivery from "../assets/delivery.jpeg";
import Restaurant from "../assets/restaurant-interior.jpeg";
import Farmer from "../assets/farmer.jpeg";
import FarmerTwo from "../assets/farmer-2.jpeg";
import WindTurbine from "../assets/wind-turbine.jpeg";
import RestaurantTwo from "../assets/restaurant.jpeg";
import { useNavigate } from "react-router-dom";

const TITLE = "Merdeo Foods";

class MyComponent extends React.PureComponent {
  render() {
    return (
      <>
        <Helmet>
          <title> {TITLE} </title>
        </Helmet>
      </>
    );
  }
}

const ComponentName = () => {
  let navigate = useNavigate();

  const Support = () => {
    navigate("/support");
  };

  const Company = () => {
    navigate("/company");
  };

  return (
    <>
      <Navigation />

      <section class="bg-white py-28 text-gray-900">
        <div class="mx-auto px-6 md:max-w-2xl lg:max-w-7xl">
          <div class="grid grid-cols-1 items-center md:pr-20 lg:grid-cols-3 lg:pr-0">
            <div>
              <span class="font-semibold uppercase tracking-normal text-green-800 lg:text-lg lg:leading-relaxed">
                The Merdeo Solution
              </span>
              <h2 class="pt-2 text-3xl font-bold leading-normal tracking-normal lg:pt-0 lg:text-[40px] lg:leading-normal lg:tracking-tight">
                Easy, Simple, Affordable
              </h2>
            </div>
            <div class="col-span-2">
              <p class="max-w-3xl pt-2 leading-loose text-gray-600 lg:pt-0 lg:text-xl lg:leading-relaxed">
                Our platform ensures your food business operates seamlessly. We
                connect urban vendors with local farmers through integrated
                technology, making everything accessible at your fingertips. Bid
                farewell to foodstock hunts; we're dedicated to sourcing local
                goods in Africa at fair prices.
              </p>
            </div>
          </div>
          <div class="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-6">
            <div class="flex flex-col gap-6 md:flex-row md:items-start lg:flex-col">
              <img
                class="aspect-square w-full max-w-sm rounded-xl object-cover md:w-1/4 lg:w-full"
                src={Veggies}
                alt=""
              />
              <div>
                <h3 class="text-xl font-semibold tracking-tight lg:text-2xl lg:leading-normal">
                  Fresh foodstock, at your fingertips
                </h3>
                <p class="mt-2 leading-relaxed text-gray-600 md:pr-20 lg:mt-4 lg:pr-0">
                  Skip the trouble of searching for vegetables and fruits. With
                  a simple tap, our platform provides instant access to all your
                  foodstock requirements.
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-6 md:flex-row md:items-start lg:flex-col">
              <img
                class="aspect-square w-full max-w-sm rounded-xl object-cover md:w-1/4 lg:w-full"
                src={Delivery}
                alt=""
              />
              <div>
                <h3 class="text-xl font-semibold tracking-tight lg:text-2xl lg:leading-normal">
                  Free 24-hour delivery
                </h3>
                <p class="mt-2 leading-relaxed text-gray-600 md:pr-20 lg:mt-4 lg:pr-0">
                  Enhance your shopping experience with our fast and efficient
                  next-day delivery, making every purchase a hassle-free
                  delight.
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-6 md:flex-row md:items-start lg:flex-col">
              <img
                class="aspect-square w-full max-w-sm rounded-xl object-cover md:w-1/4 lg:w-full"
                src={Payment}
                alt=""
              />
              <div>
                <h3 class="text-xl font-semibold tracking-tight lg:text-2xl lg:leading-normal">
                  Flexible payment options
                </h3>
                <p class="mt-2 leading-relaxed text-gray-600 md:pr-20 lg:mt-4 lg:pr-0">
                  Enjoy a seamless experience with our flexible online payment
                  options, allowing you to pay the way that suits you best.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <WaitList />

      <section class="bg-white py-28 text-gray-900">
        <div class="mx-auto px-6 md:max-w-2xl lg:max-w-7xl">
          <div class="grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-20">
            <div class="md:pr-20 lg:pr-0">
              <span class="font-semibold uppercase tracking-tight text-green-600 lg:text-xl lg:leading-relaxed">
                Increase Productivity
              </span>
              <h2 class="mt-3 pr-20 text-3xl font-bold leading-normal tracking-normal text-gray-900 lg:pr-0 lg:text-[40px] lg:leading-normal lg:tracking-tight">
                Efficiently run your food business by making data informed
                decisions
              </h2>
              <div class="mt-2 inline-flex w-full items-center gap-2 rounded-full bg-gray-100 p-0.5 lg:mt-8 lg:w-auto">
                <button class="flex-1 whitespace-nowrap rounded-full bg-amber-500 px-6 py-4 text-sm font-semibold leading-5 text-dark sm:text-base">
                  With Merdeo
                </button>
              </div>
              <div class="mt-6 text-lg font-bold tracking-tight text-gray-700 lg:text-2xl lg:leading-normal">
                Monitor Business Expenses, Effectively
              </div>
              <ul class="flex flex-col gap-4 pt-8 lg:gap-8">
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="mt-0.5 h-6 w-6 flex-shrink-0 text-green-500" />
                  <span class="leading-relaxed text-gray-700 lg:text-lg">
                    Effortlessly monitor your financial expenses on our
                    platform, equipped with intuitive dashboards and analytics
                    for strategic decision-making{" "}
                  </span>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="mt-0.5 h-6 w-6 flex-shrink-0 text-green-500" />
                  <span class="leading-relaxed text-gray-700 lg:text-lg">
                    Experience payment flexibility with a variety of options.
                    Allow your customers to choose from a range of convenient
                    and hassle-free payment methods
                  </span>
                </li>
                <li class="flex items-start gap-3">
                  <CheckCircleIcon class="mt-0.5 h-6 w-6 flex-shrink-0 text-green-500" />
                  <span class="leading-relaxed text-gray-700 lg:text-lg">
                    Transform your kitchen's menu management with our platform,
                    giving chefs the seamless capability to create, manage, and
                    update their menus.
                  </span>
                </li>
              </ul>
            </div>
            <div class="flex md:justify-center lg:items-end lg:justify-end">
              <img class="w-[486px]" src={RestaurantTwo} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-6 bg-gray-50 sm:py-8 lg:py-10">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl py-6 sm:py-4 lg:py-5">
            Connect with Merdeo Foods
          </h2>
          <div className="grid grid-cols-2 gap-6 mt-2 lg:mt-2 lg:gap-4 lg:grid-cols-4">
            <div className="relative group" onClick={Support}>
              <div className="overflow-hidden aspect-w-1 aspect-h-1">
                <img
                  className="object-cover w-full h-full transition-all duration-300 group-hover:scale-125"
                  src={Restaurant}
                  alt=""
                />
              </div>
              <div className="absolute left-3 top-3"></div>
              <div className="flex items-start justify-between mt-4 space-x-4">
                <div>
                  <h3 className="text-sm font-bold text-gray-900 sm:text-sm md:text-lg">
                    <a href="" title="">
                      Merdeo for Food Businesses
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                    </a>
                  </h3>
                </div>
              </div>
            </div>

            <div className="relative group" onClick={Support}>
              <div className="overflow-hidden aspect-w-1 aspect-h-1">
                <img
                  className="object-cover w-full h-full transition-all duration-300 group-hover:scale-125"
                  src={Farmer}
                  alt=""
                />
              </div>
              <div className="flex items-start justify-between mt-4 space-x-4">
                <div>
                  <h3 className="text-xs font-bold text-gray-900 sm:text-sm md:text-lg">
                    <a href="#" title="">
                      Merdeo for Suppliers
                      <span
                        className="absolute inset-0"
                        aria-hidden="true"
                      ></span>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="">
        <section className="relative bg-white">
          <div className="relative z-10 px-4 py-12 sm:py-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-20 xl:py-28 lg:grid lg:grid-cols-2">
            <div className="lg:pr-8">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl lg:text-5xl">
                  Merdeo's commitment{" "}
                  <span className="inline">
                    <img
                      className="inline w-auto h-8 sm:h-10 lg:h-12"
                      src="https://landingfoliocom.imgix.net/store/collection/clarity-blog/images/hero/4/shape-1.svg"
                      alt="shape-1"
                    />
                  </span>{" "}
                  is to a sustainable food future{" "}
                  <span className="inline">
                    <img
                      className="inline w-auto h-8 sm:h-10 lg:h-11"
                      src="https://landingfoliocom.imgix.net/store/collection/clarity-blog/images/hero/4/shape-2.svg"
                      alt="shape-2"
                    />
                  </span>
                </h1>
                <p className="mt-6 text-base font-normal leading-7 text-gray-900">
                  Merdeo Foods is committed to sustainable practices, including
                  reducing food wastage, promoting responsible sourcing, and
                  using renewable-energy powered cold storage.
                </p>
                <svg
                  className="w-auto h-4 mt-8 text-gray-300"
                  viewbox="0 0 172 16"
                  fill="green"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 11 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 46 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 81 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 116 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 151 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 18 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 53 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 88 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 123 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 158 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 25 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 60 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 95 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 130 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 165 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 32 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 67 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 102 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 137 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 172 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 39 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 74 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 109 1)"
                  ></line>
                  <line
                    y1="-0.5"
                    x2="18.0278"
                    y2="-0.5"
                    transform="matrix(-0.5547 0.83205 0.83205 0.5547 144 1)"
                  ></line>
                </svg>
              </div>
            </div>
          </div>
          <div className="pb-8 lg:absolute lg:inset-0 lg:pb-0">
            <div className="flex flex-col items-center justify-center overflow-hidden lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <div className="flex justify-start w-full gap-6 pb-8 overflow-x-auto snap-x">
                <div className="relative snap-start scroll-ml-6 shrink-0 first:pl-6 last:pr-6">
                  <div className="relative flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow w-60 md:w-80 group rounded-xl hover:shadow-lg hover:-translate-y-1">
                    <a
                      href="#"
                      title=""
                      className="flex shrink-0 aspect-w-4 aspect-h-3"
                    >
                      <img
                        className="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-110"
                        src={WindTurbine}
                        alt="thumbnail-1"
                      />
                    </a>
                    <div className="flex-1 px-4 py-5 sm:p-6">
                      <a href="#" title="" className="">
                        <p className="text-lg font-bold text-gray-900">
                          Cultivating a Sustainable Future
                        </p>
                        <p className="mt-3 text-sm font-normal leading-6 text-gray-500 line-clamp-3">
                          Merdeo integrates renewable energy sources such as
                          wind power to meet energy needs on the farm.
                        </p>
                      </a>
                    </div>
                    <div className="px-4 py-5 mt-auto border-t border-gray-100 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <p className="text-sm font-medium text-gray-900">
                            <a href="#" title="" className="">
                              Growth
                            </a>
                          </p>
                          <span className="text-sm font-medium text-gray-900">
                            •
                          </span>
                          <p className="text-sm font-medium text-gray-900">
                            7 Mins Read
                          </p>
                        </div>
                        <a href="#" title="" className="" role="button">
                          <svg
                            className="w-5 h-5 text-gray-300 transition-all duration-200 group-hover:text-gray-900"
                            xmlns="http://www.w3.org/2000/svg"
                            viewbox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <line x1="17" y1="7" x2="7" y2="17"></line>
                            <polyline points="8 7 17 7 17 16"></polyline>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="relative snap-start scroll-ml-6 shrink-0 first:pl-6 last:pr-6">
                  <div className="relative flex flex-col overflow-hidden transition-all duration-200 transform bg-white border border-gray-100 shadow w-60 md:w-80 group rounded-xl hover:shadow-lg hover:-translate-y-1">
                    <a
                      href="#"
                      title=""
                      className="flex shrink-0 aspect-w-4 aspect-h-3"
                    >
                      <img
                        className="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-110"
                        src={FarmerTwo}
                        alt="thumbnail-2"
                      />
                    </a>
                    <div className="flex-1 px-4 py-5 sm:p-6">
                      <a href="#" title="" className="">
                        <p className="text-lg font-bold text-gray-900">
                          Mobile Technology for Farmer Empowerment
                        </p>
                        <p className="mt-3 text-sm font-normal leading-6 text-gray-500 line-clamp-3">
                          Merdeo creates direct linkages between farmers and
                          markets, eliminating intermediaries and ensuring
                          fairer prices for agricultural produce.
                        </p>
                      </a>
                    </div>
                    <div className="px-4 py-5 mt-auto border-t border-gray-100 sm:px-6">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <p className="text-sm font-medium text-gray-900">
                            <a href="#" title="" className="">
                              Growth
                            </a>
                          </p>
                          <span className="text-sm font-medium text-gray-900">
                            •
                          </span>
                          <p className="text-sm font-medium text-gray-900">
                            7 Mins Read
                          </p>
                        </div>
                        <a href="#" title="" className="" role="button">
                          <svg
                            className="w-5 h-5 text-gray-300 transition-all duration-200 group-hover:text-gray-900"
                            xmlns="http://www.w3.org/2000/svg"
                            viewbox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <line x1="17" y1="7" x2="7" y2="17"></line>
                            <polyline points="8 7 17 7 17 16"></polyline>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end mt-2 space-x-5"></div>
            </div>
          </div>
        </section>
      </div>

      <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-16 lg:gap-x-24 gap-y-10">
            <div>
              <img
                className="object-cover w-16 h-16 rounded-full"
                src="https://res.cloudinary.com/dfnn6ethg/image/upload/v1699996809/kingsley_pyhub0.webp"
                alt=""
              />
              <blockquote>
                <p className="mt-6 text-xl leading-relaxed text-black">
                  “With Merdeo Foods, I no longer need to visit the market. That
                  means more time to focus on the things I love to do which is
                  make delicious meals and never burn out! The service is
                  remarkable”
                </p>
              </blockquote>
              <p className="mt-6 text-base font-semibold text-black">
                Kingsley
              </p>
              <p className="mt-1 text-base text-gray-600">
                CEO, Adakings Restaurant
              </p>
            </div>

            <div>
              <div className="overflow-hidden bg-white">
                <div className="p-8 lg:px-12 lg:py-10">
                  <h3 className="text-2xl font-semibold text-black">
                    Looking to stock your pantry? Connect with Merdeo today
                  </h3>
                  <p className="mt-4 text-base text-gray-600">
                    A reliable source for all your food needs in every way.
                  </p>
                  {/*
                  <a href="#" title="" className="flex items-center justify-center w-full px-4 py-4 mt-8 text-base font-semibold text-white transition-all duration-200 bg-blue-600 border-2 border-transparent rounded-md hover:bg-blue-700 focus:bg-blue-700" role="button">
                    Order Online
                  </a>
  */}
                  <a
                    href="#"
                    title=""
                    className="flex items-center justify-center w-full px-4 py-4 mt-4 text-base font-semibold text-black transition-all duration-200 bg-transparent border-2 border-black rounded-md hover:bg-black focus:bg-black hover:text-white focus:text-white"
                    role="button"
                    onClick={Support}
                  >
                    Talk to sales
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
export default ComponentName;
